import React from 'react';
import withDeviceType from './with-device-type';

function forDevice<T1 extends {}, T2 extends {}>(
  ComponentMobile: React.ComponentType<T1>,
  ComponentDesktop: React.ComponentType<T2>,
) {
  return withDeviceType(({ isMobile, isDesktop, ...props }) => {
    const Component = isMobile ? ComponentMobile : ComponentDesktop;
    return <Component {...(props as T1 & T2)} />;
  }) as React.ComponentType<T1 & T2>;
}

export default forDevice;
